
/**
 * The function takes a date as input and returns a formatted string in the format of "DD/MM/YYYY".
 * @param date - The input date that needs to be formatted.
 * @returns The function `getFormatDate` returns a formatted date string in the format of "DD/MM/YYYY"
 * (day/month/year) if a valid date is passed as an argument. If no date is passed, an empty string is
 * returned.
 */

// Get Formatted Date


export const getFormatDate = (date) => {
  if (date) {
    let Sort = date.toString().replace(/T/, ' ').replace(/\..+/, '').split(" ")
    // const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const d = new Date(Sort[0]);
    let month = d.getMonth() + 1
    let year = d.getFullYear();
    let date2 = d.getDate()

    let Convert = `${date2.toString()?.length > 1 ? date2 : "0" + date2} / ${month?.toString().length > 1 ? month : "0" + month} / ${year}`
    return Convert
  } else {
    return "";
  }

}




export const getDate = (date) => {
  if (date) {
    let Sort = date.toString().replace(/T/, ' ').replace(/\..+/, '').split(" ")
    return Sort[0]

  } else {
    return ""
  }

}





/**
 * The function converts a given date and time into a 12-hour format time string.
 * @param date - The input date that needs to be converted into a formatted time string.
 * @returns The function `convertTime` returns a formatted time string in 12-hour format with AM/PM
 * indicator.
 */

export const convertTime = (date) => {
  if (date) {
    let Sort = date.toString().replace(/T/, ' ').replace(/\..+/, '').split(" ")
    var time = Sort[1].toString()

    function tConvert(time) {
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? 'AM' : 'PM';
        time[0] = +time[0] % 12 || 12;
        time.splice(3, 1, " ");
      }
      return time.join('');
    }
    return tConvert(time)
  } else {
    return ""
  }

}

/**
* The function takes a date as input and returns the date in a specific format.
* @param date - The input parameter "date" is expected to be a date object or a string representation
* of a date in ISO format (e.g. "2021-10-15T10:30:00.000Z").
* @returns The function `getFormatDate2` returns a formatted date string extracted from the input
* `date` parameter. If the `date` parameter is not provided or is falsy, an empty string is returned.
*/
export const getFormatDate2 = (date) => {
  if (date) {
    let Sort = date.toString().replace(/T/, ' ').replace(/\..+/, '').split(" ")
    return Sort[0]

  } else {
    return ""
  }

}



