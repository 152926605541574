/**
 * Pages Routes
 */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';





const Pages = ({ match }) => (
    <div className="content-wrapper">
        <Switch>
           
     

        </Switch>
    </div>
);

export default Pages;
