
// Navigation For Dashboard Managmnent as per User Type
export const UserTypeManagment =(history ) => {
  
    const user_type =JSON.parse( localStorage.getItem("user_type"))
    const isLoggedInBool = localStorage.getItem("isLoggedIn")
    
    if (isLoggedInBool === "true") {
        if (user_type === "admin") {
            history.push("/app/dashboard/Admin/Dashboard")
        }
       
        if (user_type === "customer" || user_type === "company_admin") {
            history.push('/app/dashboard/saas');
        }
        // else {
        //     history.push("/login")
        // }

    } 

}