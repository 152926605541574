export  const str = {
    CreateAccountText: "Create Customer Account",
    LoginText: "Login",
    SignupText: "Sign Up",
    NameField: "Company/User Name",
    emailField: "Company/User Email",
    PasswardField: "Password",
    ComfompasField: "Confirm password",
    InvalidName: '*Invalid Character enter',
    InvalidEmail: '* Please provide a valid email address',
    InvalidPassword: '* Passwords must contain a minimum of eight characters, including a number, uppercase, lowercase, and one special character',
    InvalidComfimpass:'* Passwords do NOT match!',
    MandotoryField: '* This field is required',
    ForgotPassword:"Forgot Password?",
    NewUser:"New User?",
    PrivacyPolicyText:"By Registering, you are agree to our terms of use and Privacy Policy",
    createAccountText: "Create customer account",
    AlreadyAccountText:'Already have an account?',
    EnterNewPassText:"Enter New Password",
    Re_enterPassText:"Re-enter New Password",
    CancelText:"Cancel",
    changeText:"Change",
    checkedboxWarning:'Make sure you have checked the checkbox',
    askRechargeText:"Do you want to recharge",
    
}