import axios from "axios";
import { BASE_MI, BASE_URL, Guest_Auth_Token, ODM_BASE_URL, OdmUsername, OdmPassword } from "./APIConst";

export default axios.create({
	baseURL: "https://localhost:8000",
	timeout: 2000,
});

/**
 * The function registers a user by sending a POST request to a specified URL with the user's name,
 * email, and password as parameters, and returns the response.
 * @param name - The name of the user registering for the application.
 * @param email - The email parameter is a string that represents the email address of the user who
 * wants to register. It is used as a unique identifier for the user's account.
 * @param password - The password parameter is a string that represents the user's chosen password for
 * their account registration. It will be sent as part of the request body to the server for
 * authentication and account creation.
 * @returns The `register` function is returning a promise that resolves to the response object
 * returned by the `axios.post` method.
 */

export const register = (name, email, password) => {
	return axios
		.post(
			`${BASE_URL}/api/version_0/authentication/register/`,
			{
				username: name,
				email: email,
				password: password,
			},
			{
				headers: {
					"content-type": "application/json",
					accept: "application/json",
					"GUEST-AUTH-TOKEN": Guest_Auth_Token,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

export const cancelTask = async (data, token) => {
	try {
		const res = await axios.post(`${BASE_URL}/api/automaton/tasks/cancel-task/`, data, {
			headers: {
				Authorization: `Token ${token}`,
			},
		});

		return res;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const deleteTask = async (data, token) => {
	try {
		const res = await axios.post(`${BASE_URL}/api/automaton/tasks/delete-task/`, data, {
			headers: {
				Authorization: `Token ${token}`,
			},
		});

		return res;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

/**
 * The function performs a login request to a specified URL with a given username and password using
 * axios and returns the response.
 * @param username - The username of the user trying to log in.
 * @param password - The password parameter is a string that represents the user's password. It is used
 * as a credential to authenticate the user during the login process.
 * @returns The `login` function is returning a promise that resolves to the response object returned
 * by the `axios.post` method.
 */
export const login = (username, password) => {
	return axios
		.post(
			`${BASE_URL}/api/version_0/authentication/knox-login/`,
			{
				username: username,
				password: password,
			},
			{
				headers: {
					"content-type": "application/json",
					accept: "application/json",
					"GUEST-AUTH-TOKEN": Guest_Auth_Token,
				},
			}
		)
		.then((res) => {
			console.log("Login :", res);
			return res;
		});
};

/**
 * This function retrieves a user's profile information using a provided authentication token.
 * @param token - The `token` parameter is a string that represents the authentication token of a user.
 * It is used to authorize the user's access to the profile details API endpoint.
 * @returns The function `profileInfo` is returning a promise that resolves to the response object
 *
 */
export const profileInfo = (token) => {
	// console.log("IN profile API :", token);
	return axios
		.get(`${BASE_URL}/api/version_0/users/profile-details/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			console.log("profile :", res);
			return res;
		});
};

/**
 * The function updates a customer's profile information using a PATCH request with a FormData object,
 * a token, and an ID.
 * @param FormData - FormData is an object that contains key-value pairs of data to be sent in the HTTP
 * request body. It is commonly used to send files and other binary data in addition to text data.
 * @param token - The token is a string of characters that is used to authenticate and authorize a user
 * to access certain resources or perform certain actions on a website or application.
 * @param id - The `id` parameter is the unique identifier of the customer whose profile information is
 * being updated. It is used in the API endpoint URL to specify which customer's information is being
 * updated.
 * @returns The function `updateProfileInfo` returns a promise that resolves to the response object
 * returned by the axios patch request.
 */

export const updateProfileInfo = (FormData, token, id) => {
	return axios
		.patch(`${BASE_URL}/api/version_0/admin/customer/update/${id}/`, FormData, {
			headers: {
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * The function logs out a user by sending a post request to a specific endpoint with an authorization
 * token.
 * @param token - The `token` parameter is a string that represents the authentication token of the
 * user who wants to log out. This token is usually obtained after the user has successfully logged in
 * and authenticated their identity. The token is used to verify the user's identity and grant access
 * to protected resources on the server.
 * @returns The `logOut` function is returning a promise that resolves to the response object returned
 * by the `axios.post` method. The response object contains information about the HTTP response, such
 * as the status code, headers, and data.
 */

export const logOut = (token) => {
	return axios
		.post(
			`${BASE_URL}/api/version_0/authentication/knox-logout/`,
			{},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

/**
 * This function sends a request to Disable a customer with a specific user ID using an authorization
 * token.
 * @param token - The token parameter is a string that represents the authentication token for the user
 * making the API request. It is used to verify the identity of the user and ensure that they have the
 * necessary permissions to perform the requested action.
 * @param userid - The `userid` parameter is the unique identifier of the customer that needs to be
 * disabled  from the system.
 * @returns The function `CustomerDisable` is returning a promise that makes a GET request to a
 * specific endpoint with the provided `userid` parameter and `Authorization` header containing the
 * `token`. The response from the server is returned as a promise.
 */

export const CustomerDisable = (token, userid) => {
	return axios
		.get(`${BASE_URL}/api/version_0/admin/customer/delete/${userid}/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * This function sends a POST request to Enable a customer with a specific user ID using an
 * authorization token.
 * @param token - The token is a security credential that is used to authenticate the user making the
 * API request. It is passed as a header in the API request to ensure that only authorized users can
 * access the API endpoint.
 * @param userid - The `userid` parameter is the unique identifier of the customer that needs to be
 * enabled. This function is used to enable a customer account that was previously disabled by an
 * admin.
 * @returns The function `CustomerEnable` is returning a promise that resolves to the response object
 * returned by the `axios.post` request.
 */
export const CustomerEnable = (token, userid) => {
	return axios
		.post(
			`${BASE_URL}/api/version_0/admin/customer/delete/${userid}/`,
			{},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

/**
 * The function retrieves a list of coupons from an API endpoint using an authorization token and a
 * page number.
 * @param token - The token is a security credential that is used to authenticate and authorize a
 * user's access to a system or API. In this case, it is being used to authorize the user's access to
 * view the coupon list.
 * @param pageNumber - The pageNumber parameter is used to specify the page number of the coupon list
 * that needs to be retrieved. This API endpoint seems to support pagination, which means that the
 * coupon list may be split into multiple pages, and the pageNumber parameter is used to specify which
 * page to retrieve.
 * @returns The function `AdminCoupanList` is returning a promise that resolves to the response object
 *
 */
export const AdminCoupanList = (token, pageNumber) => {
	return axios
		.get(`${BASE_URL}/api/automaton/coupons/view/?page=${pageNumber}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**    Company Admin SECTION  Start*/
/**
 * This function adds a user to a company using a provided token, email, and username.
 * @param token - The token is a security credential that is used to authenticate the user making the
 * API request. It is usually obtained by logging in or registering with the API service. In this case,
 * it is used to authorize the user to create a new company user.
 * @param email - The email of the user that needs to be added to the company.
 * @param username - The username parameter is a string that represents the desired username for the
 * user being added to the company.
 * @returns The function `AddUserIn_Company` is returning a promise that resolves to the response in object
 *
 */

export const AddUserIn_Company = (token, email, username) => {
	let params = {
		email: email,
		username: username,
	};

	return axios
		.post(`${BASE_URL}/api/version_0//users/create-company-users/`, params, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			console.log("coupan create :", res);
			return res;
		});
};

/**
 * This function retrieves a list of company users from an API endpoint using a provided token and page
 * number.
 * @param token - The token is a security credential that is used to authenticate and authorize a
 * user's access to a system or resource. In this case, it is used to authenticate the user's access to
 * the company user list API endpoint.
 * @param pageNumber - The page number parameter is used to specify which page of results to retrieve
 * from the API. This is useful when there are a large number of results and they need to be split
 * across multiple pages to improve performance and reduce the amount of data that needs to be
 * transferred. The page number starts from 1
 * @returns The function `getCompanyUserList` is returning a promise that resolves to the response
 * object from making a GET request to a specific endpoint with a given page number and authorization
 * token. The response object contains the data returned by the server.
 */

export const getCompanyUserList = (token, pageNumber) => {
	return axios
		.get(`${BASE_URL}/api/version_0/users/company-users/?page=${pageNumber}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * This function retrieves a list of company users based on a search query and a token for
 * authentication.
 * @param token - The token is a string that represents the authentication token for the user making
 * the API request. It is used to verify the identity of the user and grant access to the requested
 * resources.
 * @param searchItem - The searchItem parameter is a string that represents the search query to be used
 * to search for company users. It is passed as a query parameter in the API endpoint URL.
 * @returns The function `getsearchCompanyUser` is returning a promise that resolves to the response
 * object from making a GET request to a specific endpoint with a search query parameter and an
 * authorization token in the headers. The response object contains the data returned by the server.
 */

export const getsearchCompanyUser = (token, searchItem) => {
	return axios
		.get(`${BASE_URL}/api/version_0/users/company-users/?search=${searchItem}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**    Company Admin SECTION END */

/**    Admin SECTION */

/**
 * This function sends a DELETE request to delete a coupon with a specific ID using a provided token
 * and returns the response.
 * @param token - The token is an authentication token that is used to verify the identity of the user
 * making the API request. It is passed in the headers of the API request and is used to ensure that
 * only authorized users can access the API endpoint.
 * @param projectId - The `projectId` parameter is the ID of the coupon that needs to be deleted. This
 * function sends a DELETE request to the server to delete the coupon with the specified ID.
 * @returns The `DeleteCoupon` function is returning a Promise that resolves to the response of a
 * DELETE request to the specified URL with the given parameters and headers.
 */

export const DeleteCoupon = (token, projectId) => {
	let params = {
		id: projectId,
	};

	return fetch(`${BASE_URL}/api/automaton/coupons/delete/`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: `token ${token}`,
		},
		body: JSON.stringify(params),
	}).then((res) => res);
};

/**
 * This function retrieves a list of customers from an API endpoint using a token and a page number.
 * @param token - The token is a security credential that is used to authenticate the user making the
 * API request. It is passed as a parameter to the function `getCustomerList` to ensure that only

 * @param pageNumber - The pageNumber parameter is used to specify which page of the customer list to
 * retrieve. This is useful when there are a large number of customers and the list needs to be
 * paginated to improve performance and user experience. The value of pageNumber should be an integer
 * greater than or equal to 1.
 * @returns The function `getCustomerList` is returning a promise that resolves to the response object
 * from making a GET request to a specific URL with a page number and an authorization token as
 * headers. The response object contains the data returned from the server.
 */
export const getCustomerList = (token, pageNumber) => {
	return axios
		.get(`${BASE_URL}/api/version_0/admin/customer/list/?page=${pageNumber}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * This function retrieves a list of customers based on a search query and an authentication token.
 * @param token - The `token` parameter is a string representing the authentication token required to
 * access the API endpoint. It is passed as a header in the HTTP request to authenticate the user
 * making the request.
 * @param searchItem - `searchItem` is a string parameter that represents the search query to be used
 * to search for customers in the system. The function `getSearchedCustomer` uses this parameter to
 * make a GET request to the server to retrieve a list of customers that match the search query.
 * @returns The function `getSearchedCustomer` is returning a promise that resolves to the response
 * object from an HTTP GET request to a specific URL. The URL includes a search query parameter that is
 * set to the value of the `searchItem` parameter passed to the function. The request includes an
 * Authorization header with a token value passed as the `token` parameter. The function returns the
 * response object from the
 */
export const getSearchedCustomer = (token, searchItem) => {
	return axios
		.get(`${BASE_URL}/api/version_0/admin/customer/list/?search=${searchItem}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * The function AdminCoupanSearch searches for coupons using a search term and returns the results with
 * authorization using a token.
 * @param token - The token is a security credential that is used to authenticate and authorize a
 * user's access to a system or API. In this case, it is used to authenticate the user's access to the
 * API endpoint for viewing coupons.
 * @param searchItem - The searchItem parameter is a string that represents the keyword or phrase that
 * the user wants to search for in the coupon details. This parameter is used in the API endpoint to
 * filter the results and return only the coupons that match the search criteria.
 * @returns The function `AdminCoupanSearch` is returning a promise that resolves to the response
 * object from an HTTP GET request to `/api/automaton/coupons/view/?search=`
 * with an `Authorization` header containing the provided `token`. The function also logs the response
 * object to the console before returning it.
 */

export const AdminCoupanSearch = (token, searchItem) => {
	return axios
		.get(`${BASE_URL}/api/automaton/coupons/view/?search=${searchItem}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			console.log("coupan details SEARCH:", res);
			return res;
		});
};

/**
 * This function creates a new coupon by sending a POST request to a specified API endpoint with the
 * provided parameters and returns the response.
 * @param token - The authentication token required to access the API endpoint.
 * @param coupanCode - The coupon code that will be used to apply the discount.
 * @param value - The value parameter is the amount of discount that the coupon provides. It can be a
 * percentage or a fixed amount depending on the type of discount specified in the type parameter.
 * @param type - The "type" parameter in this function refers to the type of discount being offered by
 * the coupon. It can be either a percentage discount or a fixed amount discount. The possible values
 * for this parameter are "percentage" or "fixed".
 * @param des - des stands for description and it is a parameter that contains a brief description of
 * the coupon being created.
 * @returns The function `CreateNewCoupan` is returning a promise that resolves to the response object
 * returned by the `axios.post` method. The response object contains information about the HTTP
 * response such as status code, headers, and data. The function also logs the response object to the
 * console before returning it.
 */
export const CreateNewCoupan = (token, coupanCode, value, type, des) => {
	let params = {
		coupon_text: coupanCode,
		discount_value: value,
		discount_type: type,
		description: des,
	};

	return axios
		.post(`${BASE_URL}/api/automaton/coupons/create/`, params, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			console.log("coupan create :", res);
			return res;
		});
};

/**
 * The function retrieves a list of users with low data availability from an API using a token and page
 * number.
 * @param token - The token is a security credential that is used to authenticate the user and grant
 * access to protected resources. In this case, it is used to authorize the user to access the
 * low-data-list endpoint of the customer API.
 * @param pageNumber - The page number parameter is used to specify which page of low-data users to
 * retrieve from the API. This is useful when there are a large number of low-data users and the
 * results need to be paginated for easier handling and display.
 * @returns The function `LowDataAvilableUser` is returning a promise that resolves to the response
 * object from making a GET request to a specific API endpoint. The response object is logged to the
 * console and also returned from the function.
 */

export const LowDataAvilableUser = (token, pageNumber) => {
	return axios
		.get(`${BASE_URL}/api/version_0/admin/customer/low-data-list/?page=${pageNumber}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			console.log("low data use details :", res);
			return res;
		});
};

/**
 * This function searches for low data available users using a search item and returns the results.
 * @param token - The token is a security credential that is used to authenticate the user and grant
 * access to protected resources. In this case, it is used to authorize the user to access the
 * low-data-list endpoint of the customer API.
 * @param searchItem - The searchItem parameter is a string that is used to search for low data
 * available users in the system. It is passed as a query parameter in the API endpoint URL.
 * @returns The function `SearchLowDataAvilableUser` is returning a promise that resolves to the
 * response object from making a GET request to
 *
 */
export const SearchLowDataAvilableUser = (token, searchItem) => {
	return axios
		.get(`${BASE_URL}/api/version_0/admin/customer/low-data-list/?search=${searchItem}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * This function retrieves a list of projects for a specific customer using their ID, a token for
 * authentication, and a page number for pagination.
 * @param token - The authentication token required to access the API endpoint.
 * @param pageNumber - The page number parameter is used to specify which page of results to retrieve
 * from the API. It is typically used in conjunction with pagination to limit the number of results
 * returned in a single request and make it easier to navigate through large sets of data.
 * @param id - The `id` parameter is the user ID of the customer whose projects are being requested.
 * @returns The function `CustomerProjects` is returning a promise that resolves to the response object
 * from an axios POST request to a specific API endpoint. The response object contains the data
 * returned by the API endpoint.
 */

export const CustomerProjects = (token, pageNumber, id) => {
	let params = {
		user_id: id,
	};
	console.log(params, token, "pageNumber=", pageNumber, ">>>>>>>>>>>>>>>>>>>>");
	return axios
		.post(`${BASE_URL}/api/automaton/projects/admin-view/?page=${pageNumber}`, params, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			console.log("Delete customer details :", res);
			return res;
		});
};

/**
 * This function searches for customer projects using a search term and user ID, and returns the
 * results with authorization using a token.
 * @param token - The `token` parameter is a string representing the authentication token required to
 * access the API. It is passed in the `Authorization` header of the HTTP request.
 * @param searchItem - `searchItem` is a string parameter that is used to search for a specific project
 * in the API endpoint. It is passed as a query parameter in the URL of the API call. The API will
 * return a list of projects that match the search criteria.
 * @param id - The `id` parameter is the user ID of the customer whose projects are being searched.
 * @returns The function `SearchCustomerProjects` is returning a promise that resolves to the response
 * object from an axios POST request to a specific URL with a search query parameter and a request body
 * containing a user ID. The request is made with an authorization token included in the headers. The
 * function is not modifying the response object in any way before returning it.
 */
export const SearchCustomerProjects = (token, searchItem, id) => {
	let params = {
		user_id: id,
	};
	return axios
		.post(`${BASE_URL}/api/automaton/projects/admin-view/?search=${searchItem}`, params, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/** Admin view the user acoount status
 * This is a function that sends a POST request to a specific API endpoint to retrieve the account
 * status of a user with a given ID, using a provided authentication token.
 * @param token - The `token` parameter is a string representing the authentication token that is used
 * to authorize the user making the API request. It is passed as a header in the axios post request.
 * @param id - The `id` parameter is the unique identifier of a user account. It is used to retrieve
 * the account status of a specific user.
 * @returns The function `AccountStatus` is returning a promise that resolves to the response object
 * returned by the axios POST request to the specified URL with the given parameters and headers. The
 * response object contains the data returned by the server, as well as other information such as
 * status code and headers.
 */
export const AccountStatus = (token, id) => {
	let params = {
		id: id,
	};
	return axios
		.post(`${BASE_URL}/api/version_0/users/account-status/`, params, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**    Admin SECTION END */

/**    Customer SECTION Start */

/**Total data and cunsume data
 * This function sends a GET request to retrieve the account status of a user with a given token.
 * @param token - The `token` parameter is a string representing the authentication token of a user.
 * @returns The function `UserAccountStatus` is returning a promise that resolves to the response
 * object returned by the `axios.get` request. The response object contains information about the HTTP
 * response, such as the status code, headers, and data.
 */
export const UserAccountStatus = (token) => {
	return axios
		.get(`${BASE_URL}/api/version_0/users/account-status/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * This function retrieves a list of all projects from a server using an API endpoint and returns the
 * response.
 * @param token - The token is a security credential that is used to authenticate and authorize a
 * user's access to a system or resource. In this case, it is used to authenticate the user's access to
 * the API endpoint for retrieving a list of projects.
 * @param pageNumber - The pageNumber parameter is used to specify which page
 * @returns The function `GetAlLProjectList` is returning a promise that resolves to the response
 * object from the HTTP GET request made using the axios library. The response object contains the data
 * returned by the server, as well as other information such as status code, headers,
 */
export const GetAlLProjectList = (token, pageNumber) => {
	return axios
		.get(`${BASE_URL}/api/automaton/projects/view/?page=${pageNumber}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			console.log("Delete customer details :", res);
			return res;
		});
};

/**
 * This function retrieves a list of projects from an API based on a search term and an authentication
 * token.
 * @param token - The `token` parameter is a string that represents the authentication token required
 * to access the API endpoint. It is passed in the headers of the HTTP request to authenticate the user
 * making the request.
 * @param searchItem - The `searchItem` parameter is a string that is used as a search query to filter
 * the list of projects returned by the API call. It is passed as a query parameter in the URL of the
 * API endpoint.
 * @returns The function `GetSearchProjectList` is returning a promise that resolves to the response
 * object returned by the axios GET request to the specified URL with the search query parameter and
 * authorization token in the headers. The console logs are just for debugging purposes and do not
 * affect the return value.
 */
export const GetSearchProjectList = (token, searchItem) => {
	return axios
		.get(`${BASE_URL}/api/automaton/projects/view/?search=${searchItem}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * This is a function that deletes a project using an API call with a token and project ID as
 * parameters.
 * @param token - The authentication token required to access the API endpoint.
 * @param projectId - The ID of the project that needs to be deleted.
 * @returns a promise that resolves to the response object from the axios delete request.
 */
export const DeleteProject2 = (token, projectId) => {
	let payload = {
		project_id: projectId + "",
	};

	return axios
		.delete(
			`${BASE_URL}/api/automaton/projects/delete/${52}`,

			{
				headers: {
					"content-type": "application/json",
					accept: "application/json",
					Authorization: `token ${token}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

/**
 * This function sends a DELETE request to delete a project with a specified ID using a provided token
 * for authorization.
 * @param token - The authentication token for the user making the request. It is used to verify the
 * identity of the user and ensure that they have the necessary permissions to perform the requested
 * action.
 * @param projectId - The ID of the project that needs to be deleted. This function takes in the
 * project ID as a parameter and sends a DELETE request to the server to delete the project with the
 * specified ID.
 * @returns The `DeleteProject` function is returning a Promise that resolves to the response of a
 * DELETE request to the specified URL with the specified parameters and headers.
 */

export const DeleteProject = (token, projectId) => {
	let params = {
		project_id: projectId + "",
	};

	return fetch(`${BASE_URL}/api/automaton/projects/delete/`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: `token ${token}`,
		},
		body: JSON.stringify(params),
	}).then((res) => res);
};

/**
 * Edit Project Details
 * This function sends a PATCH request to update project details with a given project ID and
 * authorization token.
 * @param token - The authentication token required to access the API endpoint.
 * @param formdata - `formdata` is a JavaScript object that contains the updated project details that
 * need to be sent to the server for editing. It is likely that this object contains key-value pairs
 * representing the fields of the project that can be edited, such as the project name, description,
 * start date, end date,
 * @param projectId - The ID of the project that needs to be updated.
 * @returns The function `EditProjectDetails` is returning a promise that resolves to the response
 * object returned by the `axios.patch` method. The response object contains information about the HTTP
 * response, such as the status code, headers, and data. The function also logs the response object to
 * the console before returning it.
 */

export const EditProjectDetails = (token, formdata, projectId) => {
	return axios
		.patch(`${BASE_URL}/api/automaton/projects/update/${projectId}/`, formdata, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * The function creates a new project by sending a POST request to a specified API endpoint with
 * project details and an image file, if provided, using axios and FormData.
 * @param token - a user authentication token for accessing the API
 * @param projectName - The name of the project that is being created.
 * @param dataset - The name of the dataset that the project will be associated with.
 * @param projectDes - projectDes is a string parameter that represents the description of a project.
 * It is used in the function CreateNewProject to create a new project with a description.
 * @param objImage - objImage is an optional parameter that represents the image file to be uploaded
 * for the project. It is of type File and is appended to the FormData object as "project_image". If no
 * image is provided, it will be null.
 * @returns a promise that resolves to the response object from the POST request made to the specified
 * URL with the provided data and headers.
 */

export const CreateNewProject = (token, projectName, dataset, projectDes, objImage) => {
	const fd = new FormData();
	if (projectName != "") {
		fd.append("project_name", projectName);
	}
	if (dataset != "") {
		fd.append("dataset_name", dataset);
	}
	if (projectDes != "") {
		fd.append("project_description", projectDes);
	}
	if (objImage != null) {
		fd.append("project_image", objImage);
	}

	///api/automaton/projects/create/`
	// /api/automaton/projects/create-v1/
	return axios
		.post(`${BASE_URL}/api/automaton/projects/create-v1/`, fd, {
			headers: {
				Authorization: `Token ${token}`,
				"Content-Type": "multipart/form-data",
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * get recharge plan ,
 * @param token - The `token` parameter is a string that represents an authentication token. It is used
 * to authenticate the user making the API request. The token is passed in the `Authorization` header
 * of the HTTP request.
 * @returns The function `GetRechargedPlan` is returning a promise that resolves to the response object
 * recharge palne object
 */

export const GetRechargedPlan = (token) => {
	return axios
		.get(`${BASE_URL}/api/automaton/recharge/get-price/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * The function checks the validity of a coupon code by sending a post request to an API endpoint with
 * the coupon code and authorization token.
 * @param token - The token is a string that represents the authentication token for the user making
 * the API request. It is used to verify the identity of the user and ensure that they have the
 * necessary permissions to access the requested resource.
 * @param coupan - The `coupan` parameter is a string that represents a coupon code that needs to be
 * validated.
 * @returns The function `CuponValidCheck` is returning a promise that resolves to the response object
 * from an axios POST request to a specific URL with a coupon code and an authorization token in the
 * request body and headers respectively.
 */
export const CuponValidCheck = (token, coupan) => {
	return axios
		.post(
			`${BASE_URL}/api/automaton/coupons/valdiate-coupon/ `,
			{
				coupon_code: coupan,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

/**
 * This function creates a task by sending a POST request to a specified URL with a dataset ID and
 * authorization token.
 * @param token - The token is a string that represents the authentication token for the user making
 * the API request. It is used to verify the identity of the user and ensure that they have the
 * necessary permissions to access the requested resource.
 * @param DatasetId - DatasetId is a parameter that represents the ID of the dataset for which a new
 * task is being created. This ID is used to identify the dataset in the backend system and associate
 * it with the new task.
 * @returns The function `CreateTask` is returning a promise that resolves to the response object from
 * making a POST request to the specified URL with the provided dataset ID and authorization token in
 * the headers. The function also logs the response object to the console before returning it.
 */
export const CreateTask = (token, DatasetId) => {
	return axios
		.post(
			`${BASE_URL}/api/automaton/tasks/create-task/ `,
			{
				dataset_id: DatasetId,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		)
		.then((res) => {
			console.log("/tasks/create-task/ :", res);
			return res;
		});
};

/**
 * This function adds files to a task using a token, task ID, and an array of file IDs.
 * @param token - The authentication token required to access the API endpoint.
 * @param TaskId -TaskId is a unique identifier for a task. It is used to specify which task the files
 * should be added to.
 * @param idArray - idArray is an array of file IDs that are being added to a task.
 * @returns The function `AddFileToTask` is returning a promise that resolves to the response object
 * from the POST request made using axios. The response object contains information about the HTTP
 * response such as status code, headers, and data.
 */

export const AddFileToTask = (token, TaskId, idArray) => {
	return axios
		.post(
			`${BASE_URL}/api/automaton/tasks/add-files/ `,
			{
				task_id: TaskId,
				dataset_input_files: idArray,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

/**
 * This function retrieves dashboard chart data using an authorization token. Project Data
 * @param token - The `token` parameter is a string representing an authentication token that is used
 * to authorize the user making the API request. It is passed as a header in the axios GET request to
 * the API endpoint.
 * @returns The `getChartData` function is returning a promise that resolves to the response object
 * from making a GET request to the specified URL with the provided token in the headers. The response
 * object contains the data returned from the server.
 */
export const getChartData = (token) => {
	return axios
		.get(`${BASE_URL}/api/automaton/charts/dashboard-chart/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**    Customer SECTION END  */

/**
 * The function adds a new customer to a website's database using a provided token and customer
 * information.
 * @param token - a user authentication token that is used to authorize the request to add a new
 * customer.
 * @param username - The username of the new customer being added.
 * @param email - The email address of the new customer being added to the system.
 * @param firstname - The first name of the new customer that is being added to the system.
 * @param lastname - The last name of the new customer being added to the system.
 * @param mobilenumber - The mobile number of the new customer being added to the system.
 * @returns The function `addNewCustomer` is returning a promise that resolves to the response object
 * from the HTTP POST request made using the axios library. The response object contains information
 * about the HTTP response such as status code, headers, and data. The function also logs the response
 * object to the console before returning it.
 */

export const addNewCustomer = (token, username, email, firstname, lastname, mobilenumber) => {
	return axios
		.post(
			`${BASE_URL}/version_0/admin/customer/add/`,
			{
				username: username,
				email: email,
				first_name: firstname,
				last_name: lastname,
				mobile_number: mobilenumber,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		)
		.then((res) => {
			console.log("Login :", res);
			return res;
		});
};

/**
 * This function retrieves customer details from an API using a token and user ID.
 * @param token - The token is a security credential that is used to authenticate and authorize a
 * user's access to a system or resource. In this case, it is used to authenticate the user's access to
 * view customer details.
 * @param userid - The `userid` parameter is the unique identifier of the customer whose details are
 * being requested. It is used in the API endpoint URL to specify which customer's details to retrieve.
 * @returns The function `viewCustomerDetails` is returning a promise that resolves to the response
 * object from making a GET request to a specific customer's details endpoint. The response object is
 * also logged to the console.
 */
export const viewCustomerDetails = (token, userid) => {
	return axios
		.get(`${BASE_URL}/version_0/admin/customer/details/${userid}/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			console.log("CustomerDetailsNew :", res);
			return res;
		});
};

/**
 * The function updates customer details using a PATCH request with authorization token and returns the
 * response.
 * @param token - an authentication token for the user making the API request
 * @param firstname - The first name of the customer that needs to be updated.
 * @param lastname - The last name of the customer that needs to be updated in the database.
 * @param mobilenumber - The `mobilenumber` parameter is a string that represents the updated mobile
 * number of a customer. It is used in the `updateCustomerDetails` function to update the mobile number
 * of a customer in the database.
 * @param userid - The unique identifier of the customer whose details are being updated.
 * @returns The function `updateCustomerDetails` returns a promise that resolves to the response object
 * returned by the axios `patch` request.
 */
export const updateCustomerDetails = (token, firstname, lastname, mobilenumber, userid) => {
	return axios
		.patch(
			`${BASE_URL}/version_0/admin/customer/update/${userid}/`,
			{
				first_name: firstname,
				last_name: lastname,
				mobile_number: mobilenumber,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

/**
 * Delete = Inactive , restrict any activity user
 * This function sends a request to delete a customer's details using a provided token and user ID.
 * @param token - The token is a security credential that is used to authenticate the user and
 * authorize access to the API endpoint. It is passed in the headers of the HTTP request to the server.
 * In this case, it is used to authenticate the admin user who is authorized to delete customer
 * details.
 * @param userid - The `userid` parameter is the unique identifier of the customer whose details are to
 * be deleted. It is used in the API endpoint URL to specify which customer's details to delete.
 * @returns The function `deleteCustomerDetails` is returning a promise that resolves to the response
 * object returned by the axios GET request to the specified URL with the provided token and userid in
 * the headers.
 */
export const deleteCustomerDetails = (token, userid) => {
	return axios
		.get(`${BASE_URL}/api/version_0/admin/customer/delete/${userid}/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

// Added by vish
// Get admins list api
/**
 *
 * @param {*} token
 * @param {*} pageNumber
 * @returns
 */
export const getAdminList = (token, pageNumber) => {
	return axios
		.get(`${BASE_URL}/version_0/admin/admin/list/?page=${pageNumber}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			console.log("Log out :", res);
			return res;
		});
};

// getSearched admin list api is not prepared like customers list
/**
 *
 * @param {*} token
 * @param {*} searchItem
 * @returns
 */
export const getSearchedAdmin = (token, searchItem) => {
	return axios
		.get(`${BASE_URL}/version_0/admin/admin/list/?search=${searchItem}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			console.log("Log out :", res);
			return res;
		});
};

/**
 * console.log("uppyInstances==", uppyInstances)
 * @param {*} token
 * @param {*} username
 * @param {*} email
 * @param {*} firstname
 * @param {*} lastname
 * @param {*} contactnumber
 * @returns
 */
export const addNewAdmin = (token, username, email, firstname, lastname, contactnumber) => {
	return axios
		.post(
			`${BASE_URL}/version_0/admin/admin/add/`,
			{
				username: username,
				email: email,
				first_name: firstname,
				last_name: lastname,
				mobile_number: contactnumber,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		)
		.then((res) => {
			console.log("Login :", res);
			return res;
		});
};

// Update admin api call
/**
 *
 * @param {*} token
 * @param {*} firstname
 * @param {*} lastname
 * @param {*} contactnumber
 * @param {*} userid
 * @returns
 */
export const updateAdminDetails = (token, firstname, lastname, contactnumber, userid) => {
	console.log("Update details", userid, firstname, lastname, contactnumber, "on clicking updateee updated details");
	return axios
		.patch(
			`${BASE_URL}/version_0/admin/admin/update/${userid}/`,
			{
				first_name: firstname,
				last_name: lastname,
				mobile_number: contactnumber,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		)
		.then((res) => {
			console.log("updated customer details :", res);
			return res;
		});
};

/**
 *
 * @param {*} token
 * @param {*} userid
 * @returns
 */
export const deleteAdminDetails = (token, userid) => {
	return axios
		.get(`${BASE_URL}/version_0/admin/admin/delete/${userid}/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			console.log("Delete admin details :", res);
			return res;
		});
};

/**
 * This function sends a GET request to view tasks with a specific dataset ID and active page, and
 * returns the response.
 * @param token - The token is a string that represents the authentication token for the user making
 * the API request. It is used to verify the identity of the user and ensure that they have the
 * necessary permissions to access the requested resource.
 * @param datasetId - The `datasetId` parameter is an identifier for a specific dataset. It is used in
 * the API endpoint to retrieve tasks associated with that dataset.
 * @param activePage - The activePage parameter is a number that represents the current page of tasks
 * that the user wants to view. It is used in the API request to specify which page of tasks to
 * retrieve from the server.
 * @returns The function `ViewTasks` is returning a promise that resolves to the response object from
 * an HTTP GET request to the specified API endpoint. The response object is logged to the console and
 * then returned.
 */
export const ViewTasks = (token, datasetId, activePage) => {
	return axios
		.get(`${BASE_URL}/api/automaton/tasks/view-tasks/${datasetId}/?page=${activePage}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			console.log(res, "responseee from view tasks api");
			return res;
		});
};

/**
 *  Search Task in ViewTaskList API handling
 * This function searches for tasks in a dataset based on a given search text and returns the results.
 * @param token - The token is a security credential that is used to authenticate and authorize a
 * user's access to a system or API. In this case, it is used to authenticate the user's access to the
 * API endpoint for viewing tasks.
 * @param datasetId - The `datasetId` parameter is an identifier for a specific dataset. It is used in
 * the API endpoint to specify which dataset's tasks should be searched.
 * @param searchText - `searchText` is a string parameter that represents the text to be searched for
 * in the tasks of a dataset. It is used in the API call to filter the tasks based on the search text.
 * @returns The function `SearchTask` is returning a promise that resolves to the response object from
 * making a GET request to the specified URL with the provided token and datasetId as query parameters
 * and searchText as a search parameter.
 */
export const SearchTask = (token, datasetId, searchText) => {
	return axios
		.get(`${BASE_URL}/api/automaton/tasks/view-tasks/${datasetId}/?search=${searchText}`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * Task view Api ,Get all fileS
 * The function `getTaskFiles` sends a POST request to a specified API endpoint with a task ID and
 * authorization token to retrieve task files and returns the response.
 * @param token - The authentication token required to access the API endpoint.
 * @param TaskId - The ID of the task for which you want to retrieve the files.
 * @param activePage - The page number of the paginated results to retrieve.
 * @returns The function `getTaskFiles` is returning a promise that resolves to the response object
 * from an axios POST request to a specific API endpoint. The response object is logged to the console
 * and then returned.
 */

export const getTaskFiles = (token, TaskId, activePage) => {
	return axios
		.post(
			`${BASE_URL}/api/automaton/tasks/view-task-files/?page=${activePage}`,
			{
				task_id: TaskId,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

/**
 * The function `getSearchTaskFiles` sends a POST request to a specified URL with a search text and
 * task ID as parameters, and returns the response.
 * @param token - The authentication token required to access the API endpoint.
 * @param TaskId - TaskId is a unique identifier for a specific task. It is used in the API call to
 * retrieve files related to that task.
 * @param searchText - The `searchText` parameter is a string that is used to search for specific files
 * related to a task. It is passed as a query parameter in the API call to filter the results based on
 * the search text.
 * @returns The function `getSearchTaskFiles` is returning a promise that resolves to the response
 * object from an axios POST request to a specific API endpoint. The response object contains the data
 * returned by the API, including any search results for the specified `searchText` parameter. The
 * function also logs the response object to the console.
 */
export const getSearchTaskFiles = (token, TaskId, searchText) => {
	return axios
		.post(
			`${BASE_URL}/api/automaton/tasks/view-task-files/?search=${searchText}`,
			{
				task_id: TaskId,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

// API FOR CREATE DATASET

/**
 * The function creates a dataset by sending a POST request to a specified URL with the provided
 * parameters and returns the response.
 * @param authToken - The authentication token required to access the API.
 * @param projectId - The ID of the project for which the dataset is being created.
 * @param datasetName - The name of the dataset that needs to be created.
 * @param comment - A string that represents any additional information or notes about the dataset
 * being created. It is an optional parameter and can be left empty if not needed.
 * @returns The function `createDataset` is returning a promise that resolves to the response object
 * from the axios POST request to create a dataset. The response object contains information about the
 * created dataset.
 */

export const createDataset = (authToken, projectId, datasetName, comment) => {
	console.log("Before creating dataset :", typeof authToken, projectId, datasetName);

	return axios
		.post(
			`${BASE_URL}/api/automaton/datasets/create/`,
			{
				project_id: projectId,
				dataset_name: datasetName,
				comment: comment,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `token ${authToken}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

/**
 * This function sends a DELETE request to delete a dataset with a specific ID from a project with a
 * specific ID, using an authentication token.
 * @param token - The authentication token for the user making the API request.
 * @param DatesetId - The ID of the dataset that needs to be deleted.
 * @param projectId - The ID of the project that the dataset belongs to.
 * @returns a Promise that resolves to the response of a DELETE request to a specific API endpoint. The
 * response could be a success message or an error message depending on the outcome of the request.
 */

export const DeleteDataset = (token, DatesetId, projectId) => {
	console.log(token, DatesetId, projectId);
	let params = {
		project_id: projectId,
		dataset_id: DatesetId,
	};

	return fetch(`${BASE_URL}/api/automaton/datasets/delete/`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: `token ${token}`,
		},
		body: JSON.stringify(params),
	}).then((res) => res);
};

/**
 * This Api Updated  Dataset
 * @param {*} datasetName
 
 * @param {*} token
 * @param {*} id
 * @returns
 */

/**
 * The function updates a dataset with a given ID, dataset name, and comment using a token for
 * authorization.
 * @param token - The authentication token required to access the API endpoint.
 * @param id - The ID of the dataset that needs to be updated.
 * @param datasetName - The name of the dataset that needs to be updated.
 * @param comment - The comment parameter is an optional string that can be passed as a parameter to
 * update the comment associated with a dataset. If a comment is provided, it will be updated for the
 * specified dataset. If not, the comment for the dataset will remain unchanged.
 * @returns The function `UpadatDataset` is returning a Promise that resolves to the response object
 * from the PATCH request made using axios library. The response object contains information about the
 * status of the request, headers, and data returned from the server.
 */
export const UpadatDataset = (token, id, datasetName, comment) => {
	let params = {};

	if (datasetName != "") {
		params["dataset_name"] = datasetName;
	}
	if (comment != "") {
		params["comment"] = comment;
	}

	return axios
		.patch(`${BASE_URL}/api/automaton/datasets/update/${id}/`, params, {
			headers: {
				Accept: "application/json",
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

// API FOR VIEW PROJECT DATASET LIST VIEW
/**
 * This function sends a POST request to retrieve a list of datasets for a specific project using an
 * authentication token and page number.
 * @param authToken - The authentication token required to access the API endpoint. It is used to
 * verify the identity of the user making the request.
 * @param projectId - The ID of the project for which you want to view the datasets.
 * @param pageNumber - The pageNumber parameter is used to specify the page number of the dataset list
 * that needs to be retrieved. This is useful when there are a large number of datasets and they need
 * to be displayed in smaller chunks or pages. By specifying the page number, the function can retrieve
 * the datasets for that particular page.
 * @returns The function `getViewProjectDatasets` returns a promise that resolves to the response
 * In project Dataset List  return
 */
export const getViewProjectDatasets = (authToken, projectId, pageNumber) => {
	return axios
		.post(
			`${BASE_URL}/api/automaton/datasets/view/?page=${pageNumber}`,
			{
				project_id: projectId,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `token ${authToken}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

/**
 * This function sends a POST request to retrieve a list of datasets for a specific project, filtered
 * by a search term, using an authentication token.
 * @param authToken - The authentication token required to access the API endpoint.
 * @param projectId - The ID of the project for which you want to retrieve datasets.
 * @param searchItem - The `searchItem` parameter is a string that is used to search for datasets in a
 * project. It is passed as a query parameter in the API request to filter the datasets based on the
 * search term.
 * @returns The function `getSearchProjectDatasets` is returning a promise that resolves to the
 * response object from an axios POST request to a specific URL with the provided parameters and
 * headers. The response object is then logged to the console and returned.
 */
export const getSearchProjectDatasets = (authToken, projectId, searchItem) => {
	return axios
		.post(
			`${BASE_URL}/api/automaton/datasets/view/?search=${searchItem}`,
			{
				project_id: projectId,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `token ${authToken}`,
				},
			}
		)
		.then((res) => {
			console.log("view projects datasets list:", res);
			return res;
		});
};

// API FOR VIEW FILES IN VIEW DATASET PAGE i.e; VIEWDATASET

/**
 * This function sends a POST request to view files associated with a dataset using an authentication
 * token and dataset ID.
 * @param authToken - The authentication token needed to access the API endpoint. It is used to verify
 * the identity of the user making the request.
 * @param datasetId - The ID of the dataset for which the files are being viewed.
 * @param pageNumber - The pageNumber parameter is used to specify the page number of the results to be
 * returned from the API call. It is passed as a query parameter in the URL of the API call.
 * @returns The function `ViewFiles` is returning a promise that resolves to the response object from
 * making a POST request to a specific API endpoint. The response object contains information about the
 * files associated with a dataset, and the function logs this response object to the console before
 * returning it.
 */
export const ViewFiles = (authToken, datasetId, pageNumber) => {
	console.log(authToken, datasetId, "authToken and datasetId on ViewFiles Api call");

	return axios
		.post(
			`${BASE_URL}/api/automaton/file-uploads/view-files/?page=${pageNumber}`,
			{
				dataset_id: datasetId,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `token ${authToken}`,
				},
			}
		)
		.then((res) => {
			console.log("res view datasets file in API:", res);
			return res;
		});
};

export const CompletedTask = (authToken, datasetId, pageNumber) => {
	console.log(datasetId, pageNumber, "??????????????????????????????");
	// /api/automaton/tasks/view-tasks/365/
	return axios
		.get(
			`${BASE_URL}/api/automaton/tasks/view-completed-tasks/${datasetId}/?page=${pageNumber}`,

			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `token ${authToken}`,
				},
			}
		)
		.then((res) => {
			console.log("res view datasets file in API:", res);
			return res;
		});
};

export const getCompletedTasksearch = (authToken, datasetId, search) => {
	return axios
		.get(
			`${BASE_URL}/api/automaton/tasks/view-completed-tasks/${datasetId}/?search=${search}`,

			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `token ${authToken}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

/**
 * This function searches for dataset files using a search term and returns the results.
 * @param authToken - The authentication token required to access the API endpoint.
 * @param datasetId - The ID of the dataset for which the files are being searched.
 * @param searchItem - The search term that is used to filter the list of files returned by the API
 * call.
 * @returns The function `SearchDatasetFiles` is returning a promise that resolves to the response
 * object from an axios POST request to a specific API endpoint. The response object contains
 * information about the success or failure of the request, as well as any data that was returned by
 * the server.
 */

export const SearchDatasetFiles = (authToken, datasetId, searchItem) => {
	// console.log(authToken, datasetId, "authToken and datasetId on ViewFiles Api call")

	return axios
		.post(
			`${BASE_URL}/api/automaton/file-uploads/view-files/?search=${searchItem}`,
			{
				dataset_id: datasetId,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `token ${authToken}`,
				},
			}
		)
		.then((res) => {
			console.log("res view datasets file in API:", res);
			return res;
		});
};

/**getModulesLiks :: Get Modules URL dynamically through API
 * This function sends a GET request to a specified URL and returns the response.
 * @returns The function `getModulesLiks` is returning a promise that resolves to the response object
 * returned by the `axios.get` method.
 */
export const getModulesLiks = () => {
	return axios
		.get(`${BASE_URL}/api/automaton/projects/misc/`, {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((res) => {
			return res;
		});
};

// NOT WORKING  KA ORIGINAL
// export const ViewFiles = (authToken, datasetId) => {
//   console.log(authToken, datasetId, "authToken and datasetId on ViewFiles Api call")
//   // return axios.get(`${BASE_URL}/api/automaton/file-uploads/view-files/`,
//   return axios.get("https://api-automaton.progfeel.co.in/api/automaton/file-uploads/view-files/",
//   {
//     "dataset_id":146
//   },
//   {
//     headers: {
//       Authorization:"token 8dc73b64bb85197e90abba84517bc1e04e91a83a096e9e35b8fbcf928a5065a9",
//       "Content-Type": "application/json"
//     }
//   })
//   .then(res => {
//     console.log("res view datasets file in API", res)
//   })
//   .catch(err => {
//     console.log("err in ViewFiles API call", err)
//   })
// }

// FILE EXPORT

/**
 * This function retrieves a list of all users from a specified API endpoint using an authorization
 * token and returns the response.
 * @param token - The token is a string that represents the authentication token of the user. It is
 * used to authenticate the user and authorize access to protected resources on the server. In this
 * case, it is passed as a header in the axios GET request to the server.

 * @returns The function `AllUser` is returning a promise that resolves to the response object 
 * 
 */
export const AllUser = (token) => {
	return axios
		.get(`${BASE_URL}/api/automaton/exports/users-export/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			// console.log("Log out :",res);
			return res;
		});
};

/**
 * Export data
 * This function retrieves all coupons list from an API using a provided token and page number.
 * @param token - The `token` parameter is a string that represents the authentication token required
 * to access the API endpoint. It is passed as a header in the axios GET request.
 * @returns The function `AllCoupons` is returning a promise that resolves to the response object from
 * an HTTP GET request to `/api/automaton/exports/coupons-export/` with an `Authorization`
 * header containing a token. The response object is returned as the resolved value of the promise.
 */

export const AllCoupons = (token) => {
	return axios
		.get(`${BASE_URL}/api/automaton/exports/coupons-export/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * Export Data
 * This function retrieves a list of low data users from an API using an authorization token.
 * @param token - The `token` parameter is a string that represents an authentication token.
 * @returns The function `AllLowDtaUser` is returning a promise that resolves to the response object
 *
 */
export const AllLowDtaUser = (token) => {
	return axios
		.get(`${BASE_URL}/api/automaton/exports/lowdata-users-export/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * This function sends a GET request to get all notifications with a given token authorization.
 * @param token - The `token` parameter is a string that represents the authentication token of the
 * user making the API request. It is used to authorize the user and ensure that only authenticated
 * users can access the notifications endpoint.
 * @returns The function `notificationAll` is returning a promise that resolves to the response object
 * from making a GET request to the specified URL with the provided headers. The response object
 * contains the data returned by the server.
 */

export const notificationAll = (token) => {
	return axios
		.get(`${BASE_URL}/api/automaton/notification/view-notifications/`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

/**
 * This function sends a POST request to mark a notification as read using a token and notification ID.
 * @param token - The token is a string that represents the authentication token of the user. It is
 * used to verify the identity of the user and grant access to protected resources. In this case, it is
 * used to authorize the user to mark a notification as seen.
 * @param id - The `id` parameter is the ID of the notification that the user has seen and wants to
 * mark as read. This ID is used in the API request to identify the specific notification that needs to
 * be marked as read.
 * @returns The `seenNotification` function is returning a promise that resolves to the response object
 * returned by the `axios.post` method.
 */

export const seenNotification = (token, id) => {
	return axios
		.post(
			`${BASE_URL}/api/automaton/notification/read-notifications/`,
			{
				notification_id: id,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `token ${token}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

export const getSecretkey = (token, currency, amount, frontEndUrl) => {
	console.log(frontEndUrl, ">>>>>>>>>>>>>>>>>>>>>>");

	return axios
		.post(
			`${BASE_URL}/api/automaton/recharge/stripe-payment-intent/`,
			{
				request_type: "auto",
				amount: amount,
				currency: currency,
				domain_url: frontEndUrl,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `token ${token}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

export const PaymentSuccess = (token, payment_intent_id, original_amount, discount_amount, coupon_id, subscription) => {
	const discountAmount = discount_amount;

	const params = {
		subscription: subscription,
		payment_intent_id: payment_intent_id,
		original_amount: original_amount,
		discount_amount: discount_amount,
	};
	if (coupon_id && Number(discountAmount) > 0) {
		params.coupon_id = coupon_id;
	}
	console.log("params=====", params);
	return axios
		.post(`${BASE_URL}/api/automaton/recharge/recharge/`, params, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

export const ODMTaskStatus = (token, OdmUID) => {
	const params = {
		uuid: OdmUID,
	};
	return axios
		.post(`${BASE_URL}/api/automaton/odm/get-task-status/`, params, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

export const ODMTaskProcess = (token, params) => {
	console.log(">>>>>>>>>>>>>".params);
	return axios
		.post(`${BASE_URL}/api/automaton/odm/task-process/`, params, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

export const ODMTaskFileDownload = (token, OdmUID, odm_project_id) => {
	const params = {
		uuid: OdmUID,
		odm_project_id: odm_project_id,
		odm_asset: "all.zip",
	};
	return axios
		.post(`${BASE_URL}/api/automaton/odm/download_odm_output/`, params, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `token ${token}`,
			},
		})
		.then((res) => {
			return res;
		});
};

export const getDatasetTasksStatus = (authToken, datasetId) => {
	return axios
		.get(
			`${BASE_URL}/api/automaton/odm/all-task-count-status/${datasetId}/`,

			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `token ${authToken}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};

//  new web odm
//OdmUsername, OdmPassword
export const getWebOdmToken = () => {
	const params = {
		username: OdmUsername,
		password: OdmPassword,
	};
	return axios
		.post(`${ODM_BASE_URL}/api/token-auth/`, params, {
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((res) => {
			return res;
		});
};

export const getWebOdmPreset = (jwtToken) => {
	return axios
		.get(
			`${ODM_BASE_URL}/api/presets/`,

			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `JWT ${jwtToken}`,
				},
			}
		)
		.then((res) => {
			return res;
		});
};
