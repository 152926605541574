export const BASE_MI = "http://184.72.60.150/api";

// export const BASE_URL = "https://api-automaton.progfeel.co.in"
// staging BASE URL:

// export const BASE_URL = "http://localhost:8001"

// LIVE BASE URL:
export const BASE_URL = "https://api.hellogeo.world";

//local
// export const BASE_URL = "http://127.0.0.1:8000";

export const Guest_Auth_Token = "b6d18755-3766-4487-9029-b540ae24d054";

// Web Odm
export const ODM_BASE_URL = "https://processing.hellogeo.world";
export const OdmUsername = "hellogeotech@kambillsystems.com";
export const OdmPassword = "Kambill@321";
